import React from "react";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";

import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { openDialog } from "../../Store/confirmationDialogSlice";

const BookingFrame = () => {
  const confirmed = useSelector((state: RootState) => state.confirmation.value);
  const dispatch = useDispatch();

  const handleClickOpen = (): void => {
    dispatch(openDialog());
  };

  return (
    <Card variant={`outlined`}>
      {confirmed ? (
        <iframe
          title={`timely-booking-widget`}
          width={`100%`}
          src="//performancemassageclinic.gettimely.com/book/embed?"
          scrolling="no"
          id="timelyWidget"
          style={{ height: 600, border: 0 }}
        ></iframe>
      ) : (
        <>
          <CardContent>
            <Typography
              variant={`h5`}
              fontWeight={600}
              gutterBottom
              color={`primary.dark`}
            >
              Online Booking
            </Typography>
            <Typography gutterBottom>
              Due to the covid-19 outbreak, to protect you, other patients and
              our staff, we need to ask you some important questions before you
              can book online.
            </Typography>
            <Typography>
              Please click the button below to begin your booking.
            </Typography>
          </CardContent>
          <CardActions disableSpacing>
            <Button fullWidth variant={`contained`} onClick={handleClickOpen}>
              Book now!
            </Button>
          </CardActions>
        </>
      )}
    </Card>
  );
};

export default BookingFrame;
