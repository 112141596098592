import React from "react";
import { Grid, Typography } from "@mui/material";
import BookingFrame from "../../../Components/BookingFrame/BookingFrame";
import { GLOBAL_CONFIG } from "../../../App";
import homeImg from "../../../Assets/group.outside.png";

const Home = () => {
  return (
    <>
      <Grid item xs={12} sm={7}>
        <Typography
          variant={`h4`}
          component={`h2`}
          fontWeight={500}
          color={`primary.dark`}
          gutterBottom
        >
          Sports and Remedial Massage
        </Typography>
        <Typography gutterBottom>
          Welcome to {GLOBAL_CONFIG.COMPANY_NAME}! We are a sports and remedial
          massage clinic based in {GLOBAL_CONFIG.COMPANY_SUBURB},{" "}
          {GLOBAL_CONFIG.COMPANY_CITY}.
        </Typography>

        <img
          src={homeImg}
          alt={`Our team`}
          style={{ width: "100%", marginTop: 10, borderRadius: 4 }}
          loading={`lazy`}
        />
      </Grid>
      <Grid item xs={12} sm={5} sx={{ paddingLeft: [null, 2] }}>
        <BookingFrame />
      </Grid>
    </>
  );
};

export default Home;
