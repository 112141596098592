import React from "react";
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from "@mui/material";
import { GLOBAL_CONFIG } from "../../../App";
import { Email, LocationOn, Phone } from "@mui/icons-material";

const Contact = () => {
  const iframeUrl = `https://www.google.com/maps/embed/v1/search?key=${process.env.REACT_APP_GOOGLE_MAP_API}&q=Performance+Massage+Clinic+Ellerslie+Auckland`;

  return (
    <>
      <Grid item xs={12}>
        <Typography
          variant={`h4`}
          component={`h2`}
          fontWeight={500}
          color={`primary.dark`}
          gutterBottom
        >
          Contact info
        </Typography>
      </Grid>
      <Grid item xs={12} sm={7}>
        <Typography gutterBottom>
          We are located inside Active Physio on the corner of Amy St and Main
          highway in Ellerslie.
        </Typography>

        <Box sx={{ marginTop: 2, minHeight: 350, position: `relative` }}>
          <div
            style={{
              position: "absolute",
              left: 0,
              right: 0,
              top: 0,
              bottom: 0,
            }}
          >
            <iframe
              id={`contact-address-map`}
              title={`contact-address-map`}
              width="100%"
              height="100%"
              frameBorder="0"
              src={iframeUrl}
              allowFullScreen
            ></iframe>
          </div>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        sm={5}
        sx={{ paddingLeft: [null, 2], paddingTop: [2, 0] }}
      >
        {/*<BookingFrame />*/}
        <address style={{ fontStyle: "normal" }}>
          <List>
            <ListItem dense disableGutters>
              <ListItemAvatar sx={{ minWidth: 40 }}>
                <LocationOn />
              </ListItemAvatar>

              <ListItemText disableTypography>
                {GLOBAL_CONFIG.COMPANY_STREET}
                <br />
                {GLOBAL_CONFIG.COMPANY_SUBURB}
                <br />
                {GLOBAL_CONFIG.COMPANY_CITY}, {GLOBAL_CONFIG.COMPANY_POSTCODE}
              </ListItemText>
            </ListItem>

            <ListItem dense disableGutters>
              <ListItemAvatar sx={{ minWidth: 40 }}>
                <Email fontSize="small" />
              </ListItemAvatar>
              <ListItemText disableTypography>
                <Link
                  underline={`hover`}
                  rel="noopener"
                  href={`mailto:${GLOBAL_CONFIG.COMPANY_EMAIL}?subject=Contact%20from%20Performance%20Massage%20website`}
                  target={`_blank`}
                >
                  {GLOBAL_CONFIG.COMPANY_EMAIL}
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem dense disableGutters>
              <ListItemAvatar sx={{ minWidth: 40 }}>
                <Phone fontSize="small" />
              </ListItemAvatar>
              <ListItemText disableTypography>
                <Link
                  underline={`hover`}
                  href={`tel:${GLOBAL_CONFIG.COMPANY_PHONE}`}
                >
                  {GLOBAL_CONFIG.COMPANY_PHONE}
                </Link>
              </ListItemText>
            </ListItem>
          </List>

          {/*<Typography gutterBottom>*/}
          {/*  Email:&nbsp;*/}
          {/*  <Link href={`mailto:${GLOBAL_CONFIG.COMPANY_EMAIL}`}>{GLOBAL_CONFIG.COMPANY_EMAIL}</Link>*/}
          {/*</Typography>*/}

          {/*<Typography>*/}
          {/*  Phone:&nbsp;*/}
          {/*  <Link href={`tel:${GLOBAL_CONFIG.COMPANY_PHONE}`}>{GLOBAL_CONFIG.COMPANY_PHONE}</Link>*/}
          {/*</Typography>*/}
        </address>
      </Grid>
    </>
  );
};

export default Contact;
