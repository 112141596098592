import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";

export interface TherapistProps {
  id?: number;
  name: string;
  imgSrc: any;
  alt?: string;
  description: any;
}

const Therapist = (props: TherapistProps) => {
  return (
    <Card elevation={0}>
      <CardMedia component="img" image={props.imgSrc} alt={props.alt} />
      <CardContent>
        <Typography gutterBottom variant="h5" component="h3">
          {props.name}
        </Typography>
        <Typography variant="body1" textOverflow={`ellipsis`}>
          {props.description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default Therapist;
