import React from "react";
import {
  Button,
  Grid,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { GLOBAL_MASSAGE_OPTIONS } from "../../../App";
import { AccessTime, CardGiftcard } from "@mui/icons-material";
import BookingFrame from "../../../Components/BookingFrame/BookingFrame";

const Prices = () => {
  return (
    <>
      <Grid item xs={12} sm={7}>
        <Typography
          variant={`h4`}
          component={`h2`}
          fontWeight={500}
          color={`primary.dark`}
          gutterBottom
        >
          Our times and prices
        </Typography>

        <Typography>
          Appointments are available throughout the week with our therapists.
        </Typography>
        <Typography>
          Please see our booking calendar to find the most convenient time for
          your appointment
        </Typography>
        <List>
          {GLOBAL_MASSAGE_OPTIONS.map(
            (massage: { length: number; price: number }, i: number) => {
              return (
                <ListItem key={`price-${massage.length}-${i}`} divider>
                  <ListItemAvatar>
                    <AccessTime />
                  </ListItemAvatar>
                  <ListItemText primary={`${massage.length} minute massage`} />
                  <ListItemSecondaryAction>
                    ${massage.price}
                  </ListItemSecondaryAction>
                </ListItem>
              );
            }
          )}
        </List>

        <p style={{ marginTop: 20 }}>
          If you'd like to purchase a gift voucher, you can click the button
          below.
        </p>

        <Button
          startIcon={<CardGiftcard />}
          target="_blank"
          href="https://bookings.gettimely.com/performancemassageclinic/bb/purchase"
          variant={"contained"}
        >
          Buy a gift certificate
        </Button>
      </Grid>
      <Grid item xs={12} sm={5} sx={{ paddingLeft: [null, 2] }}>
        <BookingFrame />
      </Grid>
    </>
  );
};

export default Prices;
