import React from "react";
import { Grid, Typography } from "@mui/material";
import photoBex from "../../../Assets/Therapists/bex.png";
import photoChantal from "../../../Assets/Therapists/chantal.png";
import photoAlice from "../../../Assets/Therapists/alice.png";
import Therapist from "../../../Components/Therapist/Therapist";

const About = () => {
  return (
    <Grid
      container
      columnSpacing={{ xs: 4, sm: 6 }}
      rowSpacing={{ xs: 4, sm: 6 }}
    >
      <Grid item xs={12}>
        <Typography
          variant={`h4`}
          component={`h2`}
          fontWeight={500}
          color={`primary.dark`}
          gutterBottom
        >
          About us
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Therapist
          id={220529}
          name={`Rebecca Fogarty`}
          imgSrc={photoBex}
          description={
            <>
              <p>
                Rebecca Fogarty is a qualified massage therapist specialising in
                sport and remedial massage. She studied in Australia for a
                'Diploma of Remedial Massage'. After returning home to New
                Zealand, she worked at a sports physio before setting up her own
                business in 2008 - 'Performance Massage Clinic'.
              </p>
              <p>
                Through her sporting career, which was highlighted by competing
                at the 2006 Commonwealth Games, she gained a love for massage
                after experiencing firsthand the great benefits of getting
                regular massage, such as the improved recovery, performance and
                injury prevention.
              </p>
              <p>
                A large number of Rebecca's clientele are athletes needing deep
                tissue and sports massage. She works with athletes ranging from
                your general 'keep fit' to the elites. Rebecca has gained a lot
                of experience with working with top elite athletes and teams.
                Some of which include; The Auckland Blues and Auckland NPC, The
                All Blacks plus many of the New Zealand and Australian
                representatives in track &amp; field and triathlon.
              </p>
              <p>
                Her work also includes many clients suffering neck/back/shoulder
                pain caused through work related stresses, those recovering from
                injury or needing relief from muscular pain and tension, and
                those needing to just relax and de-stress.
              </p>
              <p>
                Rebecca takes pride in her work with each individual, and
                believes in the importance of having a healthy body and mind.
              </p>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Therapist
          id={55700}
          name={`Chantal Sissing`}
          imgSrc={photoChantal}
          description={
            <>
              <p>
                Chantal has exceptional skills and a wealth of knowledge and
                experience!
              </p>
              <p>
                She is a South African qualified Physiotherapist, and prior to
                moving to NZ ran her own practice where her consultations
                included physiotherapy treatments and sports and remedial
                massages.
              </p>
              <p>
                Chantal works with a wide range of clients, and is very
                passionate about helping her clients achieve the best results!
              </p>
              <p>
                Whether you are wanting massage sessions to help your body to
                recover, prevent injury, improve general well-being or relieve
                muscular tension and pain, Chantal has the skills to help your
                body perform at its best.
              </p>
              <p>
                She has worked with many athletes including Ironman
                participants, runners, cyclists, MMA fighters, rugby players
                etc. Including being the therapist to SA Muaythai team.
              </p>
              <p>
                Chantal is a passionate runner and has completed numerous
                marathons and ultra marathons. Her love for health and sport has
                seen her also compete in triathlons, cycling events and being a
                keen boxer!
              </p>
            </>
          }
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Therapist
          id={530198}
          name={`Alice Caudwell`}
          imgSrc={photoAlice}
          description={
            <>
              <p>
                Alice Caudwell is a highly skilled Sports Massage Therapist with
                a deep passion for helping people harness the full potential of
                their bodies and embrace a holistic approach to health and
                well-being.
              </p>
              <p>
                Alice's professional journey began with her attainment of a
                Diploma in Sports Massage Therapy from Cardiff and Vale College.
                In addition to her expertise in massage therapy, Alice is a
                qualified nurse, with over 4.5 years of dedicated service in the
                nursing field.
              </p>
              <p>
                Alice is dedicated to providing massage treatments that cater to
                a diverse range of needs. Whether you're seeking relief from
                pain and tension, aiming to prevent injuries and aid in
                recovery, or simply looking to enhance your overall health and
                well-being, Alice is prepared to support your body in performing
                at its very best.
              </p>
              <p>
                Her path into massage therapy was shaped by a profound
                fascination with the human body and a personal understanding of
                the benefits of massage.
              </p>
              <p>
                Outside of her professional pursuits, Alice is an enthusiast for
                an active lifestyle and relishes taking on new challenges. She's
                an avid runner, having achieved significant milestones like
                participating in the Brighton Marathon and recently completing
                the Taupo half marathon. Alice also enjoys hiking, indoor
                climbing and yoga.
              </p>
              <p>
                With her remarkable massage skills, unwavering commitment to
                active living, nursing expertise, and infectious personality,
                Alice Caudwell is a valuable addition to our clinic.
              </p>
            </>
          }
        />
      </Grid>
    </Grid>
  );
};

export default About;
