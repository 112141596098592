import React from "react";
import {
  Link,
  LinkProps,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { NAVIGATION_ROUTES, NavigationRouteProps } from "../../App";
import { Grid, Typography } from "@mui/material";

const NavigationItem = (props: LinkProps) => {
  let resolved = useResolvedPath(props.to);
  let match = useMatch({ path: resolved.pathname, end: true });
  const navigate = useNavigate();
  return (
    <Grid
      item
      xs={3}
      textAlign={`center`}
      py={2}
      sx={{ backgroundColor: match ? "primary.light" : "primary.dark" }}
      onClick={() => navigate(props.to)}
    >
      <Link to={props.to} style={{ textDecoration: `none` }}>
        <Typography variant={`overline`} sx={{ color: "primary.contrastText" }}>
          {props.title}
        </Typography>
      </Link>
    </Grid>
  );
};

const Navigation = () => {
  return (
    <Grid
      container
      component={`nav`}
      spacing={0}
      maxWidth={`md`}
      margin={`auto`}
    >
      {NAVIGATION_ROUTES.map((route: NavigationRouteProps, i: number) => {
        return (
          <NavigationItem
            title={route.title}
            to={route.url}
            key={`nav-${route.title}-${i}`}
          />
        );
      })}
    </Grid>
  );
};

export { Navigation, NavigationItem };
