import React from "react";
import { Route, Routes } from "react-router-dom";
import Layout from "./Containers/Layout/Layout";
import Home from "./Containers/Routes/Home/Home";
import About from "./Containers/Routes/About/About";
import Prices from "./Containers/Routes/Prices/Prices";
import Contact from "./Containers/Routes/Contact/Contact";
import { createTheme, ThemeProvider } from "@mui/material";
import { HelmetProvider } from "react-helmet-async";
import { SpeedInsights } from "@vercel/speed-insights/react";
import { Analytics } from "@vercel/analytics/react";

declare global {
  interface Window {
    dataLayer: any;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#7e8083",
    },
    text: {
      primary: "#39464e",
    },
  },
  typography: {
    fontFamily: "Lato",
  },
});

export interface NavigationRouteProps {
  title: string;
  url: string;
  element?: React.ReactNode;
}

export const NAVIGATION_ROUTES: NavigationRouteProps[] = [
  {
    url: "/",
    title: "Home",
  },
  {
    url: "/about",
    title: "About",
    // subroute: [
    //   {
    //     url: "/about/rebecca",
    //     title: "About Rebecca Fogarty",
    //   },
    //   {
    //     url: "/about/chantal",
    //     title: "About Chantal Sissing",
    //   },
    // ],
  },
  {
    url: "/prices",
    title: "Prices",
  },
  {
    url: "/contact",
    title: "Contact",
  },
];

export const GLOBAL_MASSAGE_OPTIONS = [
  { length: 30, price: 55, timely_id: "203567%3ASV" },
  { length: 60, price: 100, timely_id: "203565%3ASV" },
  { length: 90, price: 145, timely_id: "203558%3ASV" },
];

export const GLOBAL_CONFIG = {
  COMPANY_NAME: "Performance Massage Clinic",
  COMPANY_STREET: "180 Main Highway",
  COMPANY_SUBURB: "Ellerslie",
  COMPANY_CITY: "Auckland",
  COMPANY_POSTCODE: "1051",
  COMPANY_EMAIL: "info@performancemassage.co.nz",
  COMPANY_PHONE: "021 024 10207",
  COMPANY_SOCIALS: "https://www.facebook.com/PerformanceMassageClinic/",
};

function App() {
  return (
    <ThemeProvider theme={theme}>
      {/* Routes nest inside one another. Nested route paths build upon
            parent route paths, and nested route elements render inside
            parent route elements. See the note about <Outlet> below. */}
      <HelmetProvider>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="about" element={<About />} />
            <Route path="about/rebecca" element={<About />} />
            <Route path="about/chantal" element={<About />} />
            <Route path="prices" element={<Prices />} />
            <Route path="contact" element={<Contact />} />

            {/* Using path="*"" means "match anything", so this route
                acts like a catch-all for URLs that we don't have explicit
                routes for. */}
            <Route path="*" element={<Home />} />
          </Route>
        </Routes>
        <SpeedInsights />
        <Analytics />
      </HelmetProvider>
    </ThemeProvider>
  );
}

export default App;
