import { configureStore } from "@reduxjs/toolkit";
import confirmationReducer from "./Store/confirmationSlice";
import confirmationDialogReducer from "./Store/confirmationDialogSlice";

export const store = configureStore({
  reducer: {
    confirmation: confirmationReducer,
    confirmationDialog: confirmationDialogReducer,
  },
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
