import React from "react";
import { Outlet, useLocation } from "react-router-dom";
import { Navigation } from "../../Components/Navigation/Navigation";
import { Box, Grid, useMediaQuery } from "@mui/material";
import logo from "../../Assets/logo.svg";
import {
  GLOBAL_CONFIG,
  NAVIGATION_ROUTES,
  NavigationRouteProps,
} from "../../App";
import ConfirmationDialog from "../../Components/ConfirmationDialog/ConfirmationDialog";
import Footer from "../../Components/Footer/Footer";
import TagManager from "react-gtm-module";
import PriceUpdateBanner from "../../Components/BannerMessage/PriceUpdateBanner";
import { Helmet } from "react-helmet-async";

const tagManagerArgs = {
  gtmId: `${process.env.REACT_APP_GOOGLE_TAG_MANAGER_ID}`,
};
TagManager.initialize(tagManagerArgs);

const Layout = () => {
  const showBanner = false;
  const matches = useMediaQuery("(max-width:650px)");
  const location = useLocation();

  const pathInfo: any = NAVIGATION_ROUTES.filter(
    (x: NavigationRouteProps) => x.url === location.pathname,
  ).shift();

  const pathInfoTitle = pathInfo?.title
    ? pathInfo.title
    : GLOBAL_CONFIG.COMPANY_NAME;

  window.dataLayer.push({
    event: "pageview",
    pageUrl: location.pathname,
    pageTitle: pathInfoTitle,
  });

  const pageTitle = location.pathname.match(/^\/$/) // if path '/'
    ? process.env.REACT_APP_SEO_TITLE_SUFFIX
    : `${pathInfoTitle} | ${process.env.REACT_APP_SEO_TITLE_SUFFIX}`;

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
      </Helmet>
      <Box
        component={`header`}
        sx={{ backgroundColor: "primary.main" }}
        textAlign={`center`}
        p={0}
        m={0}
      >
        <img
          src={logo}
          style={{ padding: `${matches ? 20 : 40}px 0` }}
          alt={`${GLOBAL_CONFIG.COMPANY_NAME} logo`}
          width={matches ? 145 : 265}
        />

        <Navigation />
      </Box>

      <Grid
        container
        maxWidth={`md`}
        margin={`auto`}
        spacing={0}
        px={1}
        pb={15}
        pt={3}
      >
        {showBanner && (
          <Grid item xs={12} pt={2}>
            <PriceUpdateBanner />
          </Grid>
        )}
        <Outlet />
      </Grid>

      <Footer />
      <ConfirmationDialog />
    </>
  );
};

export default Layout;
