import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { RootState } from "../../store";
import { useDispatch, useSelector } from "react-redux";
import { closeDialog } from "../../Store/confirmationDialogSlice";
import { ArrowRight, Close } from "@mui/icons-material";
import { setConfirmed, setUnconfirmed } from "../../Store/confirmationSlice";
import { GLOBAL_CONFIG } from "../../App";

export interface DialogTitleProps {
  id?: string;
  children?: React.ReactNode;
  onClose: () => void;
}

const BootstrapDialogTitle = (props: DialogTitleProps) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

const ConfirmationDialog = () => {
  const isOpen = useSelector(
    (state: RootState) => state.confirmationDialog.isOpen
  );
  const confirmed = useSelector((state: RootState) => state.confirmation.value);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeDialog());
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch(event.target.checked ? setConfirmed() : setUnconfirmed());
  };

  const [buttonActive, setButtonActive] = useState(confirmed);

  useEffect(() => {
    setButtonActive(confirmed);
  }, [confirmed]);

  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        fullWidth
        maxWidth={"md"}
        fullScreen={matches}
      >
        <BootstrapDialogTitle onClose={handleClose}>
          Pre-Booking Covid Screening Questions
        </BootstrapDialogTitle>
        <DialogContent>
          <Typography>
            If you answer yes to any of these questions we are unable to book an
            appointment with you at this stage. Please call the clinic on{" "}
            {GLOBAL_CONFIG.COMPANY_PHONE} for further information and support.
          </Typography>
          <List disablePadding>
            {/*<ListItem disableGutters>*/}
            {/*  <ListItemAvatar sx={{ minWidth: 30 }}>*/}
            {/*    <ArrowRight />*/}
            {/*  </ListItemAvatar>*/}
            {/*  <ListItemText>*/}
            {/*    In the last 14 days have you or a member of your household been*/}
            {/*    in close contact with anyone exposed to Covid-19 or similar*/}
            {/*    symptoms?*/}
            {/*  </ListItemText>*/}
            {/*</ListItem>*/}

            <ListItem disableGutters>
              <ListItemAvatar sx={{ minWidth: 30 }}>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText>
                Have you or a member of your household been tested for Covid-19
                and have a confirmed Covid-19 result or are still waiting for
                the results of your Covid test?
              </ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemAvatar sx={{ minWidth: 30 }}>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText>
                Do you have any flu-like symptoms such as fever, cough, sore
                throat, shortness of breath, ear infection, vomiting or
                diarrhea?
              </ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <ListItemAvatar sx={{ minWidth: 30 }}>
                <ArrowRight />
              </ListItemAvatar>
              <ListItemText>
                In the last 14 days have you or a member of your household
                travelled overseas or worked on an international aircraft or
                shipping vessel?
              </ListItemText>
            </ListItem>
            <ListItem disableGutters>
              <FormControl component="fieldset" variant={`filled`}>
                <FormControlLabel
                  control={
                    <Checkbox
                      onChange={(event) => handleChange(event)}
                      name="confirm"
                    />
                  }
                  label={
                    <Typography fontWeight={600} variant={`body1`}>
                      I confirm that <u>none of the above</u> applies to me. If
                      my situation changes before my appointment I will contact
                      the clinic and re-arrange my appointment.
                    </Typography>
                  }
                />
              </FormControl>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            disabled={!buttonActive}
            variant={!buttonActive ? `outlined` : `contained`}
            color={"primary"}
          >
            Continue to booking
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
