import { createSlice } from "@reduxjs/toolkit";

export interface confirmationState {
  value: boolean;
}

const initialState: confirmationState = {
  value: true,
};

export const confirmationSlice = createSlice({
  name: "confirmation",
  initialState,
  reducers: {
    setConfirmed: (state) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.value = true;
    },
    setUnconfirmed: (state) => {
      state.value = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setConfirmed, setUnconfirmed } = confirmationSlice.actions;

export default confirmationSlice.reducer;
