import React from "react";
import {
  Box,
  Grid,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import logo from "../../Assets/logo.svg";
import { GLOBAL_CONFIG } from "../../App";
import { Email, FacebookRounded, LocationOn, Phone } from "@mui/icons-material";

const Footer = () => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  const gmapSearchParams = encodeURI(
    `${GLOBAL_CONFIG.COMPANY_STREET} ${GLOBAL_CONFIG.COMPANY_SUBURB} ${GLOBAL_CONFIG.COMPANY_CITY} ${GLOBAL_CONFIG.COMPANY_POSTCODE}`
  );

  return (
    <Box
      component={`footer`}
      sx={{ backgroundColor: "primary.main" }}
      py={4}
      px={2}
      m={0}
    >
      <Grid container maxWidth={`md`} margin={`auto`} justifyContent={`center`}>
        <Grid item xs={12} sm={4} textAlign={matches ? `center` : `left`}>
          <List>
            <ListItem dense disableGutters disablePadding>
              <ListItemAvatar sx={{ minWidth: 35 }}>
                <LocationOn sx={{ color: "white" }} />
              </ListItemAvatar>
              <ListItemText disableTypography>
                <Tooltip
                  title={`Click here for directions to our clinic`}
                  placement="top-start"
                  arrow
                >
                  <Link
                    underline={`hover`}
                    rel="noopener"
                    href={`https://www.google.com/maps/dir//${gmapSearchParams}`}
                    target={`_blank`}
                    variant={`subtitle2`}
                    color={`primary.contrastText`}
                  >
                    {GLOBAL_CONFIG.COMPANY_STREET}
                    <br />
                    {GLOBAL_CONFIG.COMPANY_SUBURB}, {GLOBAL_CONFIG.COMPANY_CITY}
                  </Link>
                </Tooltip>
              </ListItemText>
            </ListItem>
            <ListItem dense disableGutters disablePadding>
              <ListItemAvatar sx={{ minWidth: 35 }}>
                <Email sx={{ color: "white" }} />
              </ListItemAvatar>
              <ListItemText disableTypography>
                <Link
                  underline={`hover`}
                  rel="noopener"
                  href={`mailto:${GLOBAL_CONFIG.COMPANY_EMAIL}?subject=Contact%20from%20Performance%20Massage%20website`}
                  target={`_blank`}
                  variant={`subtitle2`}
                  color={`primary.contrastText`}
                >
                  {GLOBAL_CONFIG.COMPANY_EMAIL}
                </Link>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          textAlign={matches ? `center` : `right`}
          alignContent={"flex-end"}
        >
          <List>
            <ListItem dense disableGutters disablePadding>
              <ListItemAvatar sx={{ minWidth: 35 }}>
                <Phone sx={{ color: "white" }} />
              </ListItemAvatar>
              <ListItemText disableTypography>
                <Link
                  underline={`hover`}
                  href={`tel:${GLOBAL_CONFIG.COMPANY_PHONE}`}
                  variant={`subtitle2`}
                  color={`primary.contrastText`}
                >
                  {GLOBAL_CONFIG.COMPANY_PHONE}
                </Link>
              </ListItemText>
            </ListItem>
            <ListItem dense disableGutters disablePadding>
              <ListItemAvatar sx={{ minWidth: 35 }}>
                <FacebookRounded sx={{ color: "white" }} />
              </ListItemAvatar>
              <ListItemText disableTypography>
                <Link
                  underline={`hover`}
                  rel="noopener"
                  href={GLOBAL_CONFIG.COMPANY_SOCIALS}
                  target={`_blank`}
                  variant={`subtitle2`}
                  color={`primary.contrastText`}
                >
                  Find us on Facebook
                </Link>
              </ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} sm={4} textAlign={`right`} mt={matches ? 10 : 0}>
          <img src={logo} width={140} alt={``} style={{ marginBottom: 20 }} />
        </Grid>
        <Grid item xs={12} textAlign={`right`} mt={matches ? 0 : 10}>
          <Typography variant={`subtitle2`} color={`primary.contrastText`}>
            &copy; {GLOBAL_CONFIG.COMPANY_NAME} {new Date().getFullYear()}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
};

export default Footer;
